<template>
    <div>
        <infoHeader :content_name="'新增车辆信息'" />

        <h4>输入需要新增的车辆信息</h4>

        <el-form
            ref="formData"
            :model="formData"
            label-width="160px"
            :label-position="'left'"
        >
            <el-form-item label="车辆品牌">
                <el-select
                    v-model="formData.car_brand_id"
                    placeholder="请选择"
                    @change="handleTypeChange"
                >
                    <el-option
                        v-for="item in value"
                        :key="item.id"
                        :label="item.brand_name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="车辆型号名称">
                <el-input v-model="formData.product_name"></el-input>
            </el-form-item>

            <!-- <el-form-item label="产品编号">
                <el-input
                    v-model.number="formData.product_code"
                    placeholder="product_code(产品编号) + production_code(生产厂商编号) + 自增id = 设备ID"
                    onkeyup="this.value=this.value.replace(/\D/g,'')"
                ></el-input>
            </el-form-item> -->

            <template v-if="brandData.content_type_code == 1">
                <el-form-item label="产品型号">
                    <el-input v-model="formData.product_series"></el-input>
                </el-form-item>
            </template>

            <el-form-item label="">
                <el-button type="primary" @click="submit()"
                    >确认无误，立即创建</el-button
                >
            </el-form-item>
        </el-form>

        <el-divider></el-divider>
    </div>
</template>

<script>
// 载入接口
// import { 接口函数 } from "@/api/接口函数定义文件";
import {
    product_create
} from '@/api/manage/operation/product/product.js';
import {
  car_brand_get,
} from "@/api/manage/operation/car/car_library.js";
// 载入方法
// import { 方法名 } from "@/utils/js文件名";
car_brand_get
export default {
    name: 'start',
    data() {
        return {
            formData: {
                product_name: null,
                product_series: null,
                product_code: undefined,
                car_brand_id: null
            },
            brandData: {
                id: null,
                content_type: null,
                content_type_code: null,
                car_brand_name: null
            },
            selectNowProductType: {},
            car_brand_table_data: [],
            value: []
        };
    },
    components: {},
    created() {
        this.initOption();
    },
    methods: {
        submit() {
            product_create(this.formData).then(res => {
                if (res.code == 200) {
                    this.value = res.data;
                    this.$router.push({ name: 'carinfo_Existed' });
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        initOption() {
            car_brand_get({}).then(res => {
                if (res.code == 200) {
                    this.value = res.data;
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        handleTypeChange(selectedItemId) {
            const selectedItem = this.value.find(
                item => item.id === selectedItemId
            );
            this.productionTypeChange(selectedItem);
        },
        productionTypeChange(item) {
            console.log(item);
            this.brandData.content_type_code = item.content_type_code;
        }
    }
};
</script>

<style lang="scss" scoped></style>
